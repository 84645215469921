





















































































































import { Component, Vue } from 'vue-property-decorator';
import Parallax from '@/components/Parallax.vue';

@Component({
  components: {
    Parallax
  },
})
export default class About extends Vue {
    data() {
        return {
        };
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.jpg$/);
        return images(image);
    }
}
